<script setup lang="ts"></script>

<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16.0446 20.7592V18.6609C19.3258 18.6609 22.0108 15.8283 22.0108 12.3663C22.0108 8.90419 19.3258 6.07159 16.0446 6.07159V3.97336C20.4523 3.97336 24 7.75017 24 12.3663C24 16.9824 20.4195 20.7592 16.0446 20.7592ZM13.0931 21.5997C12.9326 21.6017 12.7739 21.5658 12.6296 21.4948L6.5295 18.2068V5.7914L12.6296 2.5046C12.7864 2.43554 12.9556 2.3999 13.1266 2.3999C13.2976 2.3999 13.4667 2.43554 13.6235 2.5046C13.7675 2.60722 13.8849 2.74348 13.9658 2.90183C14.0467 3.06018 14.0887 3.23596 14.0883 3.41426V20.5494C14.0883 20.9345 13.9229 21.2837 13.6235 21.459C13.4628 21.5543 13.2793 21.603 13.0931 21.5997ZM0 16.738V7.29598C0 6.70106 0.430669 6.24687 0.993948 6.24687H4.57444V17.7871H0.993948C0.430669 17.7871 0 17.3329 0 16.738ZM20.0217 12.3663C20.0217 14.6743 18.2321 16.5627 16.0446 16.5627V14.4645C17.1712 14.4645 18.0325 13.5203 18.0325 12.3663C18.0325 11.2122 17.1383 10.268 16.0446 10.268V8.16981C18.2321 8.16981 20.0217 10.0582 20.0217 12.3663Z"
            fill="url(#paint0_linear_308_585)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_308_585"
                x1="12"
                y1="2.3999"
                x2="12"
                y2="21.5999"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#93A4BC" />
                <stop offset="1" stop-color="#5A6273" />
            </linearGradient>
        </defs>
    </svg>
</template>
